export const REQUEST = [
    {
        no: 152,
        title: "서울동부지방법원 관할 민사 손해배상(기) 사건을 수임하였습니다.",
        date: "2023.05.22"
    }, {
        no: 151,
        title: "서울동부지방법원 2023나***** 용역비 사건을 수임하였습니다.",
        date: "2023.03.08"
    }, {
        no: 150,
        title: "대법원 2023도**** 사기 사건을 수임하였습니다.",
        date: "2023.01.05"
    }, {
        no: 149,
        title: "대법원 2022터* 피해자보호명령등에대한재항고 사건을 수임하였습니다.",
        date: "2022.10.15"
    }, {
        no: 148,
        title: "특허법원 2022나***** 계약효력부존재확인등 사건을 수임하였습니다.",
        date: "2022.08.30"
    }, {
        no: 146,
        title: "청주지방법원 2022나***** 소유권이전등기 사건의 대법원 상고심 사건을 수임하였습니다.",
        date: "2022.05.10"
    },  {
        no: 145,
        title: "대법원 2022두***** 사건명 유족급여및장의비부지급처분취소 사건을 수임하였습니다.",
        date: "2022.02.15"
    }
]

export const PROCESS = [
    {
        no: 158,
        title: "서울동부지방법원 2023나***** 사건명 용역비",
        date: "2024.04.01",
        type: "민사"
    }, {
        no: 157,
        title: "서울동부지방법원 2024나***** 사건명 용역비",
        date: "2024.03.14",
        type: "형사"
    }, {
        no: 156,
        title: "대법원 2024도**** 사건명 사기",
        date: "2024.02.19",
        type: "형사"
    }, {
        no: 155,
        title: "서울남부지방법원 2024과** 민간임대주택에 관한 특별법 위반 과태료 부과",
        date: "2024.01.08",
        type: "행정"
    }, {
        no: 154,
        title: "대법원 2023다****** 사건명 약정금",
        date: "2023.12.29",
        type: "민사"
    }, {
        no: 153,
        title: "대법원 2023터* 사건명 피해자보호명령등에대한재항고",
        date: "2023.11.14",
        type: "형사"
    },  {
        no: 147,
        title: "대법원 2023두***** 사건명 유족급여및장의비부지급처분취소",
        date: "2023.10.02",
        type: "행정"
    }
]

