import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Carousel,
  Checkbox,
  ConfigProvider,
} from 'antd';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import emailjs from '@emailjs/browser';

import {
  PROCESS,
  REQUEST,
} from '../data/board';
import { SUCCESS } from '../data/success';
import styles from './styles/pc.module.css';

const PC = () => {
    const [activePage, setActivePage] = useState(0);
    const [checkbox, setCheckbox] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const form = useRef(); //email form
    const [isTop, setIsTop] = useState(true);
    const [SUCCESS_DATA, SET_SUCCESS_DATA] = useState([]);

    useEffect(() => {
        var speed = 1;
        var scrollable = document.querySelector('#container');
        console.log("SUCCESS : ", chunk(SUCCESS, 4));
        scrollable.addEventListener('scroll', (e) => {
            let top =scrollable.scrollTop;
            if(top === 0)  setIsTop(true);
            else setIsTop(false);
        })
    
        scrollable.addEventListener('mousedown', handleMouseDown);
        scrollable.addEventListener('touchstart', handleTouchStart);
    
        scrollable.addEventListener('mouseleave', handleMouseLeave);
        scrollable.addEventListener('touchend', handleTouchEnd);
    
        scrollable.addEventListener('mouseup', handleMouseUp);
        scrollable.addEventListener('touchcancel', handleTouchEnd);
    
        scrollable.addEventListener('mousemove', handleMouseMove);
        scrollable.addEventListener('touchmove', handleTouchMove);
    
        function handleMouseDown(e) {
          isDown = true;
          startX = e.pageX - scrollable.offsetLeft;
          scrollLeft = scrollable.scrollLeft;
        }
    
        function handleMouseLeave() {
          isDown = false;
        }
    
        function handleMouseUp() {
          isDown = false;
        }
    
        function handleMouseMove(e) {
          if (!isDown) return;
          e.preventDefault();
          var x = e.pageX - scrollable.offsetLeft;
          var walk = (x - startX) * speed; // 스크롤 속도를 조절할 수 있습니다.
          scrollable.scrollLeft = scrollLeft - walk;
        }
    
        function handleTouchStart(e) {
          isDown = true;
          startX = e.touches[0].pageX - scrollable.offsetLeft;
          scrollLeft = scrollable.scrollLeft;
        }
    
        function handleTouchEnd() {
          isDown = false;
        }
    
        function handleTouchMove(e) {
          if (!isDown) return;
          e.preventDefault();
          var x = e.touches[0].pageX - scrollable.offsetLeft;
          var walk = (x - startX) * speed; // 스크롤 속도를 조절할 수 있습니다.
          scrollable.scrollLeft = scrollLeft - walk;
        }

        
    }, [])

    //배열 자르기
    function chunk(data = [], size = 1) {
        const arr = [];
          
        for (let i = 0; i < data.length; i += size) {
          arr.push(data.slice(i, i + size));
        }
      
        return arr;
    }


    const moveClick = (id) => {
        //console.log("ID : ", "#" + id);
        let target = document.querySelector("#" + id).offsetTop;
        document.querySelector('#container').scrollTo({top: target, behavior: 'smooth'})
    }


    //스크롤 관련
    var isDown = false;
    var startX;
    var scrollLeft;

    const _scrollLeft = () => {
        var element = document.querySelector('#main_card_container');
        element.scroll({
        left: element.scrollLeft - 400,
        behavior: 'smooth' // 부드럽게 스크롤
        });
    }

    const _scrollRight = () => {
        var element = document.querySelector('#main_card_container');
        element.scroll({
        left: element.scrollLeft + 400,
        behavior: 'smooth' // 부드럽게 스크롤
        });
    }

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        category: '',
        contents: '',
        agree: false,
      });
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log("TARGET : ", e.target);
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });

        console.log("FORM : ", formData);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault(); //서비스 ID, 템플릿 ID, 퍼블릭 KEY
        
        if(formData.name.trim() === '') {
            alert("성함을 입력해주세요");
            return;
        }
        if(formData.phone.trim() === '') {
            alert("연락처를 입력해주세요");
            return;
        }
        if(formData.category.trim() === '') {
            alert("사건의 유형을 입력해주세요");
            return;
        }
        if(formData.name.trim() === '') {
            alert("사건의 내용을 입력해주세요");
            return;
        }

        if(!formData.agree) {
            alert("개인정보 및 수집 이용 동의를 체크해주세요.");
            return;
        }
        emailjs
        .sendForm('service_9spo8vk', 'template_912i4mn', form.current, {
          publicKey: 'h0CwfOw2mGG54omfE',
        })
        .then(
          () => {
            alert("상담신청이 완료되었습니다");
          },
          (error) => {
            alert("상담신청에 실패했습니다");
          },
        );
        console.log('Form data submitted:', formData);
      };

      
    return (
        <div className={styles.container} id='container'>
            <header id='start'>
                <section className={styles.header_section} style={isTop ? {backgroundColor: ''} : {backgroundColor: '#402210'}}>
                    <div style={{display: "flex", height: "90px", alignItems: "center"}}>
                        <div className={styles.header_main}>
                            <img src="/images/logo.png"/>
                        </div>
                        <div className={styles.header_menu}  onClick={() => {moveClick('start')}}>소개</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main')}}>주요수정업무</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main_press')}}>언론보도</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main_success')}}>성공사례</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main_table_container')}}>의뢰현황/사건처리현황</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main_inquery')}}>문의하기</div>
                        <div className={styles.header_menu} onClick={() => {moveClick('main_location')}}>오시는 길</div>
                    </div>
                    <section className={styles.header_line}></section>
                </section>
                <div style={{height: "90px"}}></div>
                <section className={styles.header_image}>
                    <div className={styles.image_background}></div>
                    <img src="/images/header.png"/>
                </section>

                <section className={styles.header_text}>
                    <div className={styles.header_text_bold}>어려운 법률 고민,</div>
                    <div className={styles.header_text_bold}>
                        이제 <span className={styles.header_text_highlight}>로티피</span>와 함께하세요.
                        <div className={styles.header_brown_bar}></div>
                    </div>
                    
                    <div style={{marginTop: "20px"}}></div>
                    <div className={styles.header_text_reg}>사법고시, 사법연수원 출신 대표변호사가 의뢰인들의 고민에 대한 최선의 솔루션을 제공합니다.</div>
                    <div className={styles.header_text_reg}>모든 사건은 상담부터 사건진행, 결과에 이르기까지 전부 대표변호사가 직접 진행하며, 의뢰인과 소통 도한 작업합니다.</div>

                    <div style={{marginTop: "20px"}}></div>
                    <div className={styles.header_text_reg}>믿고 맡겨주시면 최선의 결과로 보답하겠습니다</div>
                </section>

                <span className={styles.scroll_btn}>
                    <a href="#">
                        <span className={styles.mouse}>
                            <span>
                            </span>
                        </span>
                    </a>
                </span>
            </header>
            
            <section className={styles.main} id="main">
            <div className={styles.main_work} onClick={() => {}}>주요 수행 업무</div>

            <div className={styles.main_sub}>
                <div>사법연수원에서 배운 전문지식, 법원과 검찰에서 얻은 실무감각, 대형로펌에서 체득한 송무감각을 토대로,</div>
                <div>단순히 실력있는 변호사를 넘어 아픔을 공감하고, 의뢰인의 말에 귀 기울일줄 아는 변호사로 여러분과 함께 하겠습니다.</div>
            </div>

            <div className={styles.main_card_container} id='main_card_container'>
                <div className={styles.main_card}>
                <div className={styles.main_card_header}>
                    <div className={styles.main_card_header_container}>
                    <div className={styles.main_card_icon}>
                        <img src="./images/back.png" alt="행정"/>
                    </div>
                    <div>
                        <div className={styles.main_card_title}>행정</div>
                        <div className={styles.main_card_subTitle}>행정일반, 노무, 조세</div>
                    </div>
                    </div>
                    <div className={styles.main_card_line}></div>
                    <div className={styles.main_card_contetns}>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>각종 인ㆍ허가, 면허, 승인 또는 취소 소송</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>부당노동행위 관련 자문 및 소송</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>계약 서류의 작성</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>조세부과처분에 대한 행정, 민사 소송</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>조세형사사건 대응</div>
                    </div>
                    </div>
                </div>
                </div>

                <div className={styles.main_card}>
                <div className={styles.main_card_header}>
                    <div className={styles.main_card_header_container}>
                    <div className={styles.main_card_icon}>
                        <img src="./images/justice.png" alt="형사"/>
                    </div>
                    <div>
                        <div className={styles.main_card_title}>형사</div>
                        <div className={styles.main_card_subTitle}>고소대리, 수사대응, 형사재판 변론</div>
                    </div>
                    </div>
                    <div className={styles.main_card_line}></div>
                    <div className={styles.main_card_contetns}>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>고소대리</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>경찰, 검찰 수사대응</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>형사재판 대응</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>합의대행</div>
                    </div>
                    </div>
                </div>
                </div>

                <div className={styles.main_card}>
                <div className={styles.main_card_header}>
                    <div className={styles.main_card_header_container}>
                    <div className={styles.main_card_icon}>
                        <img src="./images/raw.png" alt="민사"/>
                    </div>
                    <div>
                        <div className={styles.main_card_title}>민사</div>
                        <div className={styles.main_card_subTitle}>손해배상, 약정금, 가압류, 가처분</div>
                    </div>
                    </div>
                    <div className={styles.main_card_line}></div>
                    <div className={styles.main_card_contetns}>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>대여금 청구</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>약정금 청구</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>공사대급 청구 (인테리어)</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>주위토지통행권 확인</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>각종 손해배상청구 (교통사고, 상간소송)</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>각종 가압류, 가처분</div>
                    </div>
                    </div>
                </div>
                </div>

                <div className={styles.main_card}>
                <div className={styles.main_card_header}>
                    <div className={styles.main_card_header_container}>
                    <div className={styles.main_card_icon}>
                        <img src="./images/home.png" alt="가사"/>
                    </div>
                    <div>
                        <div className={styles.main_card_title}>가사</div>
                        <div className={styles.main_card_subTitle}>이혼, 상속</div>
                    </div>
                    </div>
                    <div className={styles.main_card_line}></div>
                    <div className={styles.main_card_contetns}>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>이혼소송</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>상속재산분할소송, 유류분 청구소송</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>한정후견심판, 성년후견심판</div>
                    </div>
                    <div className={styles.main_card_row}>
                        <div className={styles.main_card_checkbox}>
                        <img src="./images/CheckCircle.svg" alt="Check"/>
                        </div>
                        <div className={styles.main_card_text}>기타 각종 신청 사건</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            
            <div className={styles.main_card_arrow_container}>
                <div className={styles.main_card_arrow_left} onClick={_scrollLeft}>
                <img src="images/arrow_black.svg" alt="Left Arrow"/>
                </div>
                <div className={styles.main_card_arrow_right} onClick={_scrollRight}>
                <img src="images/arrow_white.svg" alt="Right Arrow"/>
                </div>
            </div>
            </section>

            <section className={styles.main_press} id="main_press">
                
                <div className={styles.main_press_header}>
                    <div className={styles.main_press_title}>
                        언론보도
                    </div>
                    <div className={styles.main_press_subTitle}>
                        법률사무소 로티피 관련 언론에 보도된 기사들입니다
                    </div>
                </div>

                    
                <div className={styles.main_press_news}>
                    <a href="http://www.newstomato.com/ReadNews.aspx?no=1212569&inflow=N" target="_blank" style={{textDecoration: "none"}}>
                        <div className={styles.main_news_card}>
                            <div className={styles.main_news_image}>
                                <img src="https://newsroom.etomato.com/userfiles/20231215_163055_507870012.jpg"/>
                            </div>
                            <div className={styles.main_news_title}>
                                January 30, 2024
                            </div>

                            <div className={styles.main_news_subTitle}>
                                '나체 유포 협박'…조직화되는 '몸캠피싱'
                            </div>

                            <div className={styles.main_news_provider}>
                                뉴스토마토
                            </div>
                        </div>
                    </a>

                    <a href="https://lawtalknews.co.kr/article/6Z4KGLLLNJPC" target="_blank" style={{textDecoration: "none"}}>
                        <div className={styles.main_news_card}>
                            <div className={styles.main_news_image}>
                                <img src="https://d2ilb6aov9ebgm.cloudfront.net/1703739682345643.jpg"/>
                            </div>
                            <div className={styles.main_news_title}>
                                December 28, 2023
                            </div>

                            <div className={styles.main_news_subTitle}>
                                돈 빌린 사람이 “그동안 법정이자율 초과해 이자 지급했으니 원금 못 갚겠다”는데, 어쩌지?
                            </div>

                            <div className={styles.main_news_provider}>
                                로톡뉴스
                            </div>
                        </div>
                    </a>

                    <a href="https://lawtalknews.co.kr/article/6W9A9COL73WW" target="_blank" style={{textDecoration: "none"}}>
                        <div className={styles.main_news_card}>
                            <div className={styles.main_news_image}>
                                <img src="https://d2ilb6aov9ebgm.cloudfront.net/1693902385402617.jpg"/>
                            </div>
                            <div className={styles.main_news_title}>
                                September 09, 2024
                            </div>

                            <div className={styles.main_news_subTitle}>
                                공무원인데 노래방 갔다가 성매매…“해임당할까 봐 너무 걱정돼”
                            </div>

                            <div className={styles.main_news_provider}>
                                로톡뉴스
                            </div>
                        </div>
                    </a>
                </div>
            </section>

            <section className={styles.main_success} id="main_success">
                <div className={styles.main_success_header}>
                    <div className={styles.main_success_title}>
                    성공사례
                    </div>
                    <div className={styles.main_success_subTitle}>
                    로티피의 대표변호사 최광희 변호사의 성공한 사례들입니다
                    </div>
                </div>
                <ConfigProvider
                theme={{
                    components: {
                    Carousel: {
                        dotOffset: "-62px",
                        dotHeight: "7px",
                        colorBgContainer: "#402210",
                        dotActiveWidth: "50px"
                        /* here is your component tokens */
                    },
                    },
                }}
                >
                <Carousel >
                    {
                        
                        chunk(SUCCESS, 4).map((item, index) => (
                            <div>
                                <div className={styles.main_success_card_container}>
                                    {
                                        item.map((subItem, subIndex) => (
                                            <div className={styles.main_success_card} key={index * 4 + subIndex + 1} onClick={() => {navigate(`/success/${index * 4 + subIndex + 1}`)}}>
                                                <div className={styles.main_success_image}>
                                                    <img src="./images/document.png" alt="성매매알선등행위"/>
                                                </div>
                                                <div className={styles.main_success_sub_card}>
                                                <div className={styles.main_success_sub_title}>
                                                    {subItem.title}
                                                </div>
                                                <div className={styles.main_success_sub_subTitle}>
                                                    {subItem.subTitle}
                                                </div>
                                                <div className={styles.main_success_sub_btn}>
                                                    {subItem.type}
                                                </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                   
                </Carousel>

                </ConfigProvider>
                
            </section>

            <section className={styles.main_table_container} id="main_table_container">
                <div className={styles.main_table}>
                    <div className={styles.main_table_title}>의뢰현황</div>
                    <div className={styles.main_table_header}>
                    <div className={styles.main_table_flex_1} style={{ fontWeight: 'bold' }}>NO.</div>
                    <div className={styles.main_table_flex_4} style={{ fontWeight: 'bold' }}>제목</div>
                    <div className={styles.main_table_flex_1} style={{ fontWeight: 'bold' }}>작성일</div>
                    </div>

                    <div className={styles.main_table_contents}>
                    {REQUEST.map((data, index) => (
                        <div className={styles.main_table_row} key={index}>
                        <div className={styles.main_table_flex_1} style={{ fontWeight: 'bold' }}>{data.no}</div>
                        <div className={`${styles.main_table_flex_4} ${styles.main_table_overflow}`} style={{ fontWeight: 'bold' }}>
                            <p>{data.title}</p>
                        </div>
                        <div className={styles.main_table_flex_1} >{data.date}</div>
                        </div>
                    ))}
                    </div>
                </div>

                <div className={styles.main_table}>
                    <div className={styles.main_table_title}>사건처리현황</div>
                    <div className={styles.main_table_header}>
                    <div className={styles.main_table_flex_1}  style={{ fontWeight: 'bold' }}>NO.</div>
                    <div className={styles.main_table_flex_1}  style={{ fontWeight: 'bold' }}>구분</div>
                    <div className={styles.main_table_flex_3}  style={{ fontWeight: 'bold' }}>제목</div>
                    <div className={styles.main_table_flex_1}  style={{ fontWeight: 'bold' }}>작성일</div>
                    </div>
                    <div className={styles.main_table_contents}>
                    {PROCESS.map((data, index) => (
                        <div className={styles.main_table_row} key={index}>
                        <div className={styles.main_table_flex_1} style={{ fontWeight: 'bold' }}>{data.no}</div>
                        <div className={styles.main_table_flex_1} style={{ fontWeight: 'bold' }}>{data.type}</div>
                        <div className={`${styles.main_table_flex_3} ${styles.main_table_overflow}`} style={{ fontWeight: 'bold' }}>
                            <p>{data.title}</p>
                        </div>
                        <div className={styles.main_table_flex_1}>{data.date}</div>
                        </div>
                    ))}
                    </div>
                </div>
            </section>

            <section className={styles.main_inquery} id="main_inquery">
                <div className={styles.main_inquery_image_group}>
                    <img className={styles.main_inquery_image} src="./images/inquery.png" alt="문의하기" />
                </div>

                <div className={styles.main_inquery_container}>
                    <div className={styles.main_inquery_header}>
                    <div className={styles.main_inquery_title}>문의하기</div>
                    <div className={styles.main_inquery_subTitle}>
                        문의주신 내용은 대표변호사 최광희 변호사가 전부 직접 상담합니다.
                    </div>
                    </div>

                    <form ref={form} className={styles.main_inquery_table} onSubmit={handleSubmit}>
                    <div className={styles.main_inquery_table_row}>
                        <div className={styles.main_inquery_input_group}>
                        <div className={styles.main_inquery_text}>성함*</div>
                        <input
                            className={styles.main_inquery_input}
                            name="name"
                            id="name"
                            placeholder="성함"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        </div>

                        <div className={styles.main_inquery_input_group}>
                        <div className={styles.main_inquery_text}>연락처*</div>
                        <input
                            type="number"
                            className={styles.main_inquery_input}
                            name="phone"
                            id="phone"
                            placeholder="`-`제외"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        </div>
                    </div>

                    <div className={styles.main_inquery_table_row}>
                        <div className={styles.main_inquery_input_group}>
                        <div className={styles.main_inquery_text}>사건의 유형*</div>
                        <input
                            className={styles.main_inquery_input}
                            name="category"
                            id="category"
                            placeholder="유형을 선택해주세요"
                            value={formData.category}
                            onChange={handleChange}
                        />
                        </div>
                    </div>

                    <div className={styles.main_inquery_table_row}>
                        <div className={styles.main_inquery_input_group}>
                        <div className={styles.main_inquery_text}>사건의 내용*</div>
                        <input
                            className={styles.main_inquery_input}
                            name="contents"
                            id="contents"
                            placeholder="간단한 사건 내용을 입력해주세요."
                            value={formData.contents}
                            onChange={handleChange}
                        />
                        </div>
                    </div>

                    <div className={styles.main_inquery_checkbox}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: "#3F210F"
                            },
                        }}>
                        <Checkbox onChange={handleChange} name="agree" checked={formData.agree}>&nbsp;</Checkbox>
                    </ConfigProvider>
                        <div className={styles.main_inquery_checkbox_text} onClick={() => {setCheckbox(!checkbox)}} checked={checkbox}>개인정보 및 수집 이용에 동의합니다.</div>
                    </div>

                    <div className={styles.main_inquery_license}>
                        <div>개인정보 및 수집 이용에 관한 약관 내용입니다.</div>
                        <div>
                        이름 및 연락처 등 개인정보를 수집할 경우, 반드시 개인정보처리방침에 대한 내용을 고지해야 합니다.
                        </div>
                    </div>

                    <button type="submit" className={styles.main_inquery_btn}>
                        빠른 상담신청
                    </button>
                    </form>
                </div>
            </section>

            <section className={styles.main_location} id="main_location">
                <div className={styles.main_location_header}>
                <div className={styles.main_location_title}>
                    오시는길
                </div>
                <div className={styles.main_location_subTitle}>
                    서울특별시 서초구 서초중앙로 123, 3층
                </div>
                </div>

                <div className={styles.main_location_map}>
                <img src="/images/map.png" alt="오시는길 지도" />
                </div>
            </section>

            <section className={styles.main_info}>
                <div className={styles.main_info_text}>
                    <div>
                    로티피 법률사무소&nbsp;&nbsp;|&nbsp;&nbsp;서울특별시 서초구 서초중앙로 123, 3층
                    </div>
                    <div>
                    사업자 등록번호 594-34-01232&nbsp;&nbsp;Fax. 02-6269-3300&nbsp;&nbsp;E-mail. junvler@law-tp.com
                    </div>
                    <div>
                    대표이사 : 최광희&nbsp;&nbsp;|&nbsp;&nbsp;개인정보 보호책임자 : 이준호
                    </div>
                    
                    <div style={{ marginTop: '20px' }}></div>
                    <div>© 로티피 All Rights Reserved.</div>
                </div>

                <div className={styles.main_info_logo}>
                    <img src="/images/logo2.svg" alt="로티피 로고" />
                </div>
            </section>
        </div>
    )
}

export default PC;