import {
  useEffect,
  useState,
} from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import Mobile from './pages/mobile';
import NotFound from './pages/notfound';
import PC from './pages/pc';
import Mobile_Success from './pages/success/mobile_success';
import PC_Success from './pages/success/pc_success';

function App() {
  const [isMobile, setIsMobile] = useState(/Mobi/i.test(window.navigator.userAgent)); // "Mobi" 가 User agent에 포함되어 있으면 모바일
  
  useEffect(() => {
  }, [])

  return (
    
    <div className="App">
      <BrowserRouter>
          <Routes>
            {
              isMobile ?  //모바일일 경우 
              <>
                <Route path="/" element={<Mobile />}></Route>
                <Route path="/success/:id" element={<Mobile_Success/>}></Route>
              </>
               : // PC일 경우
              <>
                <Route path="/" element={<PC />}></Route>
                <Route path="/success/:id" element={<PC_Success />}></Route>
                
              </> 
              
            }
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
