import {
  useEffect,
  useState,
} from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import { SUCCESS } from '../../data/success';
import styles from './styles/mobile_success.module.css';

const Mobile_Success = () => {
    const navigate = useNavigate();
    const [list, setList] = useState(false)
    const params = useParams();
    const item = SUCCESS[params.id - 1];

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [])

    return (
        <div>
            <section  className={styles.main_header}>
                <img src='/images/logo.png'/>
            </section>

            <div style={{height: "100vh", overflow: "auto"}}>
                <section className={styles.success_board}>
                    <div >
                        <div style={{height: "60px"}}></div>
                        <div className={styles.success_header_tit}>
                            성공 사례
                        </div>
                        <div className={styles.success_header_sub_tit}>
                            로티피의 대표변호사 최광희 변호사의 성공한 사례들입니다
                        </div>
                    </div>

                    <div className={styles.success_detail_tit}>
                        <div style={{flex: 4, textAlign: 'left', fontSize: "14px", marginRight: "15px"}}>{item.subTitle} - [{item.type}]</div>
                        <div  className={styles.success_type}>
                            <div>{item.type}</div></div>
                    </div>

                    <div className={styles.success_detail_img}>
                        <img src={item.image}></img>
                    </div>

                    <div className={styles.success_explain}>
                        <div>1. 관할: {item.detail.관할}</div>
                        <div>2. 성명: {item.detail.성명}</div>
                        <div>3. 가족: {item.detail.가족}</div>
                        <div>4. 직업: {item.detail.직업}</div>
                        <div>5. 소득: {item.detail.소득}</div>
                        <div>6. 채무: {item.detail.채무}</div>
                        <div>7. 월별제금: {item.detail.월별제금}</div>
                        <div>8. 탕감금액: {item.detail.탕감금액}</div>
                        <div>9. 변제율: {item.detail.변제율}</div>
                        
                        <br/>
                        <div>상담 내용</div>
                        <div>{item.상담내용}</div>
                        
                        <br/> 
                        <div>진행 내용</div> 
                        <div>{item.진행내용}</div>
                    
                    </div>

                    <div className={styles.success_go_list} onClick={() => {window.history.back()}}>
                        목록    
                    </div>   

                </section>

                <section className={styles.main_info}>
                    <div className={styles.main_info_text}>
                        <div>
                        로티피 법률사무소
                        </div>
                        <div>서울특별시 서초구 서초중앙로 123, 3층</div>
                        <div>
                        사업자 등록번호 594-34-01232&nbsp;&nbsp;Fax. 02-6269-3300&nbsp;&nbsp;E-mail. junvler@law-tp.com
                        </div>
                        <div>
                        대표이사 : 최광희&nbsp;&nbsp;|&nbsp;&nbsp;개인정보 보호책임자 : 이준호
                        </div>
                        
                        <div style={{ marginTop: '20px' }}></div>
                        <div>© 로티피 All Rights Reserved.</div>
                    </div>

                    <div className={styles.main_info_logo}>
                        <img src="/images/logo2.svg" alt="로티피 로고" />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Mobile_Success;