export const SUCCESS = [
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    },
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "조건부기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    },
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "조건부기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    },
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "조건부기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    },
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "조건부기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    },
    {
        title: "성매매알성등행위",
        subTitle: "[성구매자교육프로그램조건부기소유예]",
        type: "조건부기소유예",
        image: "/images/documents/document.png",
        detail: {
            관할: "서울회생법원",
            성명: "홍길동",
            가족: "1인",
            직업: "직장인",
            소득: "1,800,000",
            채무: "원금 35,689,138원",
            월변제금: "462,933원x24개월",
            탐감금액: "24,578,746원",
            변제율: "원금의 31.10%"
        },
        상담내용: "의뢰인은 이탈북자며 한국에서 사업을 하던중 탈세혐의등으로 폐업후 사업할 당시 리스했던 차량위약금이 문제가 되서 상담 요청을 하셨습니다",
        진행내용: "상담시 만 30세 미만이였으며 기초수급과 소액의 아르바이트 소득으로 만 30세 미만의 청년이었습니다.\n청년은 개인회생 기간이 24개월로 단축되는 요건이 충족되면 24개월로 가능하여 월 변제금도 적게 70%정도 탕감을 받고 개시결정을 받았습니다."
    }
]