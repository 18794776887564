import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Carousel,
  Checkbox,
  ConfigProvider,
} from 'antd';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import emailjs from '@emailjs/browser';

import {
  PROCESS,
  REQUEST,
} from '../data/board';
import { SUCCESS } from '../data/success';
import styles from './styles/mobile.module.css';

const Mobile = () => {
    const [tab, setTab] = useState(0);
    const [list, setList] = useState(false);
    const [isTop, setIsTop] = useState(true);
    const navigate = useNavigate();
    const form = useRef();
    const [isBoard, setIsBoard] = useState(false);

    useEffect(() => {
        let inter = setInterval(() => {
            let body = document.querySelector("#main_body");
            if(body.scrollTop < 20) {
                setIsTop(true);
            } else {
                setIsTop(false);
            }
        }, 100)
        
        return () => {
            clearInterval(inter);

        }
    })

    const [checkbox, setCheckbox] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        category: '',
        contents: '',
        agree: false,
      });
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log("TARGET : ", e.target);
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });

        console.log("FORM : ", formData);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault(); //서비스 ID, 템플릿 ID, 퍼블릭 KEY
        
        if(formData.name.trim() === '') {
            alert("성함을 입력해주세요");
            return;
        }
        if(formData.phone.trim() === '') {
            alert("연락처를 입력해주세요");
            return;
        }
        if(formData.category.trim() === '') {
            alert("사건의 유형을 입력해주세요");
            return;
        }
        if(formData.name.trim() === '') {
            alert("사건의 내용을 입력해주세요");
            return;
        }


        if(!formData.agree) {
            alert("개인정보 및 수집 이용 동의를 체크해주세요.");
            return;
        }
        emailjs
        .sendForm('service_9spo8vk', 'template_912i4mn', form.current, {
            publicKey: 'h0CwfOw2mGG54omfE',
        })
        .then(
          () => {
            alert("상담신청이 완료되었습니다");
          },
          (error) => {
            alert("상담신청에 실패했습니다");
          },
        );
        console.log('Form data submitted:', formData);
      };

    const moveClick = (id) => {
        //console.log("ID : ", "#" + id);
        let body = document.querySelector("#main_body");
        let target = document.querySelector("#" + id).offsetTop;
        body.scrollTo({top: target, behavior: 'smooth'})
    }


    return (
        <div className={styles.mobile_main}>
            <div className={styles.header_main}>
                <div className={styles.header_main_title} style={isTop ? list ? {backgroundColor: "white"} : {backgroundColor: "rgba(0, 0, 0, 0)"} : {backgroundColor: "rgba(64, 34, 16, 1)", borderBottom: "0px"}}>
                    <div className={styles.header_logo}>
                        {
                            isTop ? list ? <img src='/images/logo2.svg'/> : <img src='/images/logo.svg' style={{height: "23px"}}/> : <img src='/images/logo.svg' style={{height: "23px"}}/>
                        }
                        
                    </div>
                    <div className={styles.header_menu} onClick={() => {setList(!list)}}>
                        {
                            isTop ? list ? <img src='/images/menu.svg'/> : <img src='/images/menu_white.svg'/> : <img src='/images/menu_white.svg'/>
                        }
                    </div>
                </div>
                <div style={list ? {opacity: 1, zIndex: 20} : {opacity: 0, zIndex: 0}} className={`${styles.header_main_background}`} id="list_background" onClick={() => {setList(false)}}>

                </div>

                <motion.div animate={ list ?  { height: "calc(65px * 6)"} : {height: 0}} className={`${styles.header_main_list} `}>
                    <div className={tab === 0 ? styles.main_list_active : ''} onClick={() => {setTab(0); setList(false); moveClick('main_body')}}>주요수행업무</div>
                    <div className={tab === 1 ? styles.main_list_active : ''} onClick={() => {setTab(1); setList(false); moveClick('main_press')}}>언론보도</div>
                    <div className={tab === 2 ? styles.main_list_active : ''} onClick={() => {setTab(2); setList(false); moveClick('main_success')}}>성공사례</div>
                    <div className={tab === 3 ? styles.main_list_active : ''} onClick={() => {setTab(3); setList(false); moveClick('main_table_container')}}>의뢰현황/사건처리현황</div>
                    <div className={tab === 4 ? styles.main_list_active : ''} onClick={() => {setTab(4); setList(false); moveClick('main_inquery')}}>문의하기</div>
                    <div className={tab === 5 ? styles.main_list_active : ''} onClick={() => {setTab(5); setList(false); moveClick('main_location')}}>오시는 길</div>
                </motion.div>
            </div>

            <div className={styles.main_body} id='main_body'>
                <div className={styles.main_image_container}>
                    <img className={styles.main_body_image} src='/images/header.png'></img>
                    <div className={styles.main_body_image_background}></div>
                </div>

                <div className={styles.main_image_contents}>
                    <div className={styles.main_big_contents}>어려운 법률 고민,</div>
                    <div className={styles.main_big_contents}><span style={{color: "rgba(222, 197, 91, 1)"}}>로티피</span>와 함께하세요.</div>
                    <div className={styles.main_big_line}></div>

                    <div style={{marginTop: "50px"}}></div>
                    <div>사법고시, 사법연수원 출신 대표변호사가</div>
                    <div>의뢰인들의 고민에 대한 최선의 솔루션을 제공합니다.</div>
                    <br/>
                    <div>믿고 맡겨주시면 최선의 결과로 보답하겠습니다</div>


                    <div className={styles.main_work_board}>
                        <div className={styles.main_work_board_container}>
                            <div className={styles.main_work_board_title}>주요수행업무</div>
                            <div className={styles.main_work_board_sub}>
                                <div>단순히 실력있는 변호사를 넘어 아픔을 공감하고,</div>
                                <div>의뢰인의 말에 귀 기울일줄 아는 변호사로 함께 하겠습니다.</div>
                            </div>
                        </div>

                        <div style={{marginTop: "25px"}}></div>
                        <div >
                        <Carousel  className={styles.main_card_group} >
                            <div className={styles.main_card}>
                                <div className={styles.main_card_header}>
                                    <div className={styles.main_card_header_container}>
                                    <div className={styles.main_card_icon} style={{width: "50px", height: "50px"}}>
                                        <img src="./images/back.png" alt="행정"/>
                                    </div>
                                    <div style={{marginLeft: "15px", marginTop: "5px", textAlign: "left"}}>
                                        <div className={styles.main_card_title}>행정</div>
                                        <div className={styles.main_card_subTitle}>행정일반, 노무, 조세</div>
                                    </div>
                                    </div>
                                    <div className={styles.main_card_line}></div>
                                    <div className={styles.main_card_contetns}>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>각종 인ㆍ허가, 면허, 승인 또는 취소 소송</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>부당노동행위 관련 자문 및 소송</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>계약 서류의 작성</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>조세부과처분에 대한 행정, 민사 소송</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>조세형사사건 대응</div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.main_card}>
                                <div className={styles.main_card_header}>
                                    <div className={styles.main_card_header_container}>
                                    <div className={styles.main_card_icon} style={{width: "50px", height: "50px"}}>
                                        <img src="./images/justice.png" alt="형사"/>
                                    </div>
                                    <div style={{marginLeft: "15px", marginTop: "5px", textAlign: "left"}}>
                                        <div className={styles.main_card_title}>형사</div>
                                        <div className={styles.main_card_subTitle}>고소대리, 수사대응, 형사재판 변론</div>
                                    </div>
                                    </div>
                                    <div className={styles.main_card_line}></div>
                                    <div className={styles.main_card_contetns}>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>고소대리</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>경찰, 검찰 수사대응</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>형사재판 대응</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>합의대행</div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.main_card}>
                                <div className={styles.main_card_header}>
                                    <div className={styles.main_card_header_container}>
                                    <div className={styles.main_card_icon} style={{width: "50px", height: "50px"}}>
                                        <img src="./images/raw.png" alt="민사"/>
                                    </div>
                                    <div style={{marginLeft: "15px", marginTop: "5px", textAlign: "left"}}>
                                        <div className={styles.main_card_title}>민사</div>
                                        <div className={styles.main_card_subTitle}>손해배상, 약정금, 가압류, 가처분</div>
                                    </div>
                                    </div>
                                    <div className={styles.main_card_line}></div>
                                    <div className={styles.main_card_contetns}>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>대여금 청구</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>약정금 청구</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>공사대급 청구 (인테리어)</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>주위토지통행권 확인</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>각종 손해배상청구 (교통사고, 상간소송)</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>각종 가압류, 가처분</div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.main_card}>
                                <div className={styles.main_card_header}>
                                    <div className={styles.main_card_header_container}>
                                    <div className={styles.main_card_icon} style={{width: "50px", height: "50px"}}>
                                        <img src="./images/home.png" alt="가사"/>
                                    </div>
                                    <div style={{marginLeft: "15px", marginTop: "5px", textAlign: "left"}}>
                                        <div className={styles.main_card_title}>가사</div>
                                        <div className={styles.main_card_subTitle}>이혼, 상속</div>
                                    </div>
                                    </div>
                                    <div className={styles.main_card_line}></div>
                                    <div className={styles.main_card_contetns}>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>이혼소송</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>상속재산분할소송, 유류분 청구소송</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>한정후견심판, 성년후견심판</div>
                                    </div>
                                    <div className={styles.main_card_row}>
                                        <div className={styles.main_card_checkbox}>
                                        <img src="./images/CheckCircle.svg" alt="Check"/>
                                        </div>
                                        <div className={styles.main_card_text}>기타 각종 신청 사건</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                        </div>
                      
                        
                    </div>

                    <section className={styles.main_press} id="main_press">
                        <div className={styles.main_press_header}>
                            <div className={styles.main_press_title}>
                                언론보도
                            </div>
                            <div className={styles.main_press_subTitle}>
                                법률사무소 로티피 관련 언론에 보도된 기사들입니다
                            </div>
                        </div>

                            
                        <div className={styles.main_press_news}>
                            <a href="http://www.newstomato.com/ReadNews.aspx?no=1212569&inflow=N" target="_blank" style={{textDecoration: "none"}}>
                                <div className={styles.main_news_card}>
                                    <div className={styles.main_news_image}>
                                        <img src="https://newsroom.etomato.com/userfiles/20231215_163055_507870012.jpg"/>
                                    </div>
                                    <div className={styles.main_news_contents}>
                                        <div className={styles.main_news_title}>
                                            January 30, 2024
                                        </div>

                                        <div className={styles.main_news_subTitle}>
                                            '나체 유포 협박'…조직화되는 '몸캠피싱'
                                        </div>

                                        <div className={styles.main_news_provider}>
                                            뉴스토마토
                                        </div>
                                    </div>
                                    
                                </div>
                            </a>

                            <a href="https://lawtalknews.co.kr/article/6Z4KGLLLNJPC" target="_blank" style={{textDecoration: "none"}}>
                                <div className={styles.main_news_card}>
                                    <div className={styles.main_news_image}>
                                        <img src="https://d2ilb6aov9ebgm.cloudfront.net/1703739682345643.jpg"/>
                                    </div>
                                    <div className={styles.main_news_contents}>
                                        <div className={styles.main_news_title}>
                                            December 28, 2023
                                        </div>

                                        <div className={styles.main_news_subTitle}>
                                            돈 빌린 사람이 “그동안 법정이자율 초과해 이자 지급했으니 원금 못 갚겠다”는데, 어쩌지?
                                        </div>

                                        <div className={styles.main_news_provider}>
                                            로톡뉴스
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a href="https://lawtalknews.co.kr/article/6W9A9COL73WW" target="_blank" style={{textDecoration: "none"}}>
                                <div className={styles.main_news_card}>
                                    <div className={styles.main_news_image}>
                                        <img src="https://d2ilb6aov9ebgm.cloudfront.net/1693902385402617.jpg"/>
                                    </div>

                                    <div className={styles.main_news_contents}>
                                        <div className={styles.main_news_title}>
                                            September 09, 2024
                                        </div>

                                        <div className={styles.main_news_subTitle}>
                                            공무원인데 노래방 갔다가 성매매…“해임당할까 봐 너무 걱정돼”
                                        </div>

                                        <div className={styles.main_news_provider}>
                                            로톡뉴스
                                        </div>
                                        
                                    </div>
                                </div>
                            </a>
                        </div>
                    </section>


                    <section className={styles.main_success} id="main_success">
                        <div className={styles.main_success_header}>
                            <div className={styles.main_success_title}>
                            성공사례
                            </div>
                            <div className={styles.main_success_subTitle}>
                            로티피의 대표변호사 최광희 변호사의 성공한 사례들입니다
                            </div>
                        </div>
                        <ConfigProvider
                        theme={{
                            components: {
                            Carousel: {
                                dotOffset: "-30px",
                                dotHeight: "7px",
                                colorBgContainer: "#402210",
                                dotActiveWidth: "50px"
                                /* here is your component tokens */
                            },
                            },
                        }}
                        >
                        <Carousel>
                        {
                            SUCCESS.map((item,index) => (
                                <div>
                                    <div className={styles.main_success_card_container}>
                                    <div className={styles.main_success_card}  onClick={() => {navigate(`/success/${index + 1}`)}}>
                                            <div className={styles.main_success_image}>
                                                <img src={item.image} alt={item.type}/>
                                            </div>
                                            <div className={styles.main_success_sub_card}>
                                            <div className={styles.main_success_sub_title}>
                                                {item.title}
                                            </div>
                                            <div className={styles.main_success_sub_subTitle}>
                                                {item.subTitle}
                                            </div>
                                            <div className={styles.main_success_sub_btn}>
                                                {item.type}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))   
                        }
                      
                        </Carousel>

                        </ConfigProvider>
                        
                    </section>

                    <section className={styles.main_table_container} id="main_table_container">
                        <div className={styles.main_table}>
                            <div className={styles.main_table_title_wrap}>
                                <div className={`${styles.main_table_title} ${isBoard ?'' :  styles.main_table_title_active}`} onClick={() => {setIsBoard(false)}}>의뢰현황</div>
                                <div className={`${styles.main_table_title} ${isBoard ? styles.main_table_title_active : ''}`} onClick={() => {setIsBoard(true)}}>사건처리현황</div>

                            </div>
                            <div className={styles.main_table_header}>
                            <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>NO.</div>
                            {
                                isBoard ? <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>구분</div> : <></>
                            }
                            <div className={styles.main_table_flex_4} style={{ fontWeight: 'bold' }}>제목</div>
                            <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>작성일</div>
                            </div>

                            <div className={styles.main_table_contents}>
                            {
                                !isBoard ? (
                                    REQUEST.map((data, index) => (
                                        <div className={styles.main_table_row} key={index}>
                                            <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>{data.no}</div>
                                            <div className={`${styles.main_table_flex_4} ${styles.main_table_overflow}`} style={{ fontWeight: 'bold' }}>
                                                <p>{data.title}</p>
                                            </div>
                                            <div className={styles.main_table_flex_2}>{data.date}</div>
                                        </div>
                                    ))
                                ) : (
                                    PROCESS.map((data, index) => (
                                        <div className={styles.main_table_row} key={index}>
                                            <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>{data.no}</div>
                                            <div className={styles.main_table_flex_2} style={{ fontWeight: 'bold' }}>{data.type}</div>
                                            <div className={`${styles.main_table_flex_4} ${styles.main_table_overflow}`} style={{ fontWeight: 'bold' }}>
                                                <p>{data.title}</p>
                                            </div>
                                            <div className={styles.main_table_flex_2}>{data.date}</div>
                                        </div>
                                    ))
                                )
                            }
                            </div>
                        </div>
                       
                    </section>

                    <section className={styles.main_inquery} id="main_inquery">
                        <div className={styles.main_inquery_image_group}>
                            <img className={styles.main_inquery_image} src="./images/inquery.png" alt="문의하기" />
                        </div>

                        <div className={styles.main_inquery_container}>
                            <div className={styles.main_inquery_header}>
                            <div className={styles.main_inquery_title}>문의하기</div>
                            <div className={styles.main_inquery_subTitle}>
                            문의 주신 내용은 대표변호사가 전부 직접 상담합니다.
                            </div>
                            </div>

                            <form ref={form} className={styles.main_inquery_table} onSubmit={handleSubmit}>
                            <div className={styles.main_inquery_table_row}>
                                <div className={styles.main_inquery_input_group}>
                                    <div className={styles.main_inquery_text}>성함*</div>
                                    <input
                                        type="text"
                                        className={styles.main_inquery_input}
                                        name="name"
                                        id="name"
                                        placeholder="성함"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className={styles.main_inquery_table_row}>
                                <div className={styles.main_inquery_input_group}>
                                    <div className={styles.main_inquery_text}>연락처*</div>
                                    <input
                                        type="number"
                                        className={styles.main_inquery_input}
                                        name="phone"
                                        id="phone"
                                        placeholder="`-`제외"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>


                            <div className={styles.main_inquery_table_row}>
                                <div className={styles.main_inquery_input_group}>
                                <div className={styles.main_inquery_text}>사건의 유형*</div>
                                <input
                                    className={styles.main_inquery_input}
                                    name="category"
                                    id="category"
                                    placeholder="유형을 선택해주세요"
                                    value={formData.category}
                                    onChange={handleChange}
                                />
                                </div>
                            </div>

                            <div className={styles.main_inquery_table_row}>
                                <div className={styles.main_inquery_input_group}>
                                <div className={styles.main_inquery_text}>사건의 내용*</div>
                                <input
                                    className={styles.main_inquery_input}
                                    name="contents"
                                    id="contents"
                                    placeholder="간단한 사건 내용을 입력해주세요."
                                    value={formData.contents}
                                    onChange={handleChange}
                                />
                                </div>
                            </div>

                            <div className={styles.main_inquery_checkbox}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: "#3F210F"
                                    },
                                }}>
                                <Checkbox onChange={handleChange} name="agree" checked={formData.agree}>&nbsp;</Checkbox>
                            </ConfigProvider>
                                <div className={styles.main_inquery_checkbox_text} onClick={() => {setFormData({
                                    ...formData,
                                    agree: !formData.agree
                                })}}>개인정보 및 수집 이용에 동의합니다.</div>
                            </div>

                            <div className={styles.main_inquery_license}>
                                <div>개인정보 및 수집 이용에 관한 약관 내용입니다.</div>
                                <div>
                                이름 및 연락처 등 개인정보를 수집할 경우, 반드시 개인정보처리방침에 대한 내용을 고지해야 합니다.
                                </div>
                            </div>

                            <button type="submit" className={styles.main_inquery_btn}>
                                빠른 상담신청
                            </button>
                            </form>
                        </div>
                    </section>

                    <section className={styles.main_location} id="main_location">
                        <div className={styles.main_location_header}>
                        <div className={styles.main_location_title}>
                            오시는길
                        </div>
                        <div className={styles.main_location_subTitle}>
                            서울특별시 서초구 서초중앙로 123, 3층
                        </div>
                        </div>

                        <div className={styles.main_location_map}>
                        <img src="/images/map.svg" alt="오시는길 지도" />
                        </div>
                    </section>

                    <section className={styles.main_info}>
                        <div className={styles.main_info_text}>
                            <div>
                            로티피 법률사무소
                            </div>
                            <div>서울특별시 서초구 서초중앙로 123, 3층</div>
                            <div>
                            사업자 등록번호 594-34-01232&nbsp;&nbsp;Fax. 02-6269-3300&nbsp;&nbsp;E-mail. junvler@law-tp.com
                            </div>
                            <div>
                            대표이사 : 최광희&nbsp;&nbsp;|&nbsp;&nbsp;개인정보 보호책임자 : 이준호
                            </div>
                            
                            <div style={{ marginTop: '20px' }}></div>
                            <div>© 로티피 All Rights Reserved.</div>
                        </div>

                        <div className={styles.main_info_logo}>
                            <img src="/images/logo2.svg" alt="로티피 로고" />
                        </div>
                    </section>
                </div>

                
                    
                <div style={{minHeight: "330px", backgroundColor: "white", width: "100vw"}}></div>

            </div>
        </div>
    )
}

export default Mobile;